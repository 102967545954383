"use client";

import React, { createContext, useContext, useMemo, useState, useEffect, ReactNode } from "react";
import { useRouter } from "next/navigation";
import { environments } from "@/app/(docs)/routes-config";

interface DocumentContextType {
  selectedEnv: string;
  setSelectedEnv: (env: string) => void;
}

const INIT_STATE: DocumentContextType = {
  selectedEnv: environments[0].env,
  setSelectedEnv: () => {},
};

const Context = createContext<DocumentContextType>(INIT_STATE);

export function useDocumentContext() {
  return useContext(Context);
}

interface DocumentContextProviderProps {
  children: ReactNode;
}

export function DocumentContextProvider({ children }: DocumentContextProviderProps) {
  const router = useRouter();

  // Initialize selectedEnv with the value from localStorage, or fallback to INIT_STATE
  const [selectedEnv, setSelectedEnv] = useState<string>(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("selectedEnv") || INIT_STATE.selectedEnv;
    }
    return INIT_STATE.selectedEnv;
  });

  // Redirect whenever selectedEnv changes
  useEffect(() => {
    const currentPath = window.location.pathname;
    const basePath = `/docs/${selectedEnv}`;

    // Check if the current path starts with the basePath
    if (selectedEnv && !currentPath.startsWith(basePath)) {
      localStorage.setItem("selectedEnv", selectedEnv);
      router.push(basePath);
    }
  }, [selectedEnv, router]);

  const value = useMemo(
    () => ({
      selectedEnv,
      setSelectedEnv,
    }),
    [selectedEnv],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default DocumentContextProvider;
