import _ from "lodash";
import { pageRoutes } from "@/app/(docs)/routes-config";
import Anchor from "../anchor";
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "./sheet";

function LeftbarMenu({ env, isSheet = false }) {
  const routesByEnv = _.find(pageRoutes, { env });
  return (
    <>
      {_.map(routesByEnv?.pages, (page) => {
        const { title, href, items } = page;

        return (
          <div className="flex flex-col gap-3 mt-5" key={href}>
            <h4 className="font-medium sm:text-sm">{title}</h4>
            <div className="flex flex-col gap-3 sm:text-sm dark:text-neutral-300/85 text-neutral-800 ml-0.5">
              {items.map((subItem) => {
                const key = `/docs/${subItem.href}`;
                const Comp = (
                  <Anchor activeClassName="font-medium text-primary" key={key} href={key}>
                    {subItem.title}
                  </Anchor>
                );
                return isSheet ? (
                  <SheetClose key={key} asChild>
                    {Comp}
                  </SheetClose>
                ) : (
                  Comp
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default LeftbarMenu;
