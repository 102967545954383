"use client";
import _ from "lodash";
import { use, useEffect, useState } from "react";
import { Spacer } from "@nextui-org/react";
import { environments } from "@/app/(docs)/routes-config";
import Anchor from "./anchor";
import { ScrollArea } from "./ui/scroll-area";
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "./ui/sheet";
import { Logo, NavMenu } from "./navbar";
import { Button } from "./ui/button";
import { AlignLeftIcon } from "lucide-react";
import Select from "./ui/select";
import LeftbarMenu from "./ui/menu";
import { useDocumentContext } from "@/app/(docs)/context/DocumentContext";

export function Leftbar() {
  const { selectedEnv, setSelectedEnv } = useDocumentContext();

  return (
    <aside className="md:flex hidden flex-[0.9] min-w-[230px] sticky top-16 flex-col h-[92.75vh] overflow-y-auto">
      <ScrollArea className="py-4">
        <Select label="Environment" items={environments} value={selectedEnv} setValue={setSelectedEnv} radius="sm" />
        <LeftbarMenu env={selectedEnv} />
      </ScrollArea>
    </aside>
  );
}

export function SheetLeftbar() {
  const { selectedEnv, setSelectedEnv } = useDocumentContext();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="lg:hidden flex">
          <AlignLeftIcon />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 px-0" side="left">
        <SheetHeader>
          <SheetClose className="px-5" asChild>
            <Logo />
          </SheetClose>
        </SheetHeader>
        <ScrollArea className="flex flex-col gap-4">
          <div className="mx-2 px-5">
            <Spacer y={2} />
            <Select
              label="Environment"
              items={environments}
              value={selectedEnv}
              setValue={setSelectedEnv}
              radius="sm"
            />
            <LeftbarMenu env={selectedEnv} isSheet />
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
