"use client";

import { Icon } from "@iconify/react";
import Link from "next/link";
import Image from "next/image";
import { buttonVariants } from "./ui/button";
import Search from "./search";
import Anchor from "./anchor";
import { SheetLeftbar } from "./leftbar";
import { SheetClose } from "./ui/sheet";
import config from "@/config";
const { company, github } = config;
import { useDocumentContext } from "@/app/(docs)/context/DocumentContext";
import { getDefaultPageHref } from "@/app/(docs)/routes-config";

export function Navbar() {
  return (
    <nav className="w-full border-b h-16 sticky top-0 z-50 lg:px-4 px-2 backdrop-filter backdrop-blur-xl bg-opacity-5">
      <div className="sm:p-3 p-2 max-w-[1530px] mx-auto h-full flex items-center justify-between gap-2">
        <div className="flex items-center gap-5">
          <SheetLeftbar />
          <div className="flex items-center gap-8">
            <div className="sm:flex hidden">
              <Logo />
            </div>
            <div className="lg:flex hidden items-center gap-5 text-sm font-medium text-muted-foreground">
              <NavMenu />
            </div>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2">
            <Search />
            <div className="flex">
              <Link href={github} className={buttonVariants({ variant: "ghost", size: "icon" })}>
                <Icon icon="lucide:github" width={18} height={18} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export function Logo() {
  return (
    <Link href="/" className="px-5 flex items-center gap-2.5">
      <Image
        src="/image/logo-dark-horizontal.png"
        width={220}
        height={32}
        alt={`${company} logo`}
        className="w-48	min-w-[180px]"
      />
    </Link>
  );
}

export function NavMenu({ isSheet = false }) {
  const { selectedEnv } = useDocumentContext();

  const navTabs = [
    {
      title: "Docs",
      href: getDefaultPageHref(`/docs/${selectedEnv}`),
    },
    {
      title: "Develop",
      href: getDefaultPageHref(`/docs/${selectedEnv}/develop`),
    },
    {
      title: "Blog",
      href: "/blog",
    },
  ];

  return (
    <>
      {navTabs.map((item) => {
        const Comp = (
          <Anchor
            key={item.title + item.href}
            activeClassName="text-black dark:text-white font-semibold"
            absolute
            href={item.href}
          >
            {item.title}
          </Anchor>
        );
        return isSheet ? (
          <SheetClose key={item.title + item.href} asChild>
            {Comp}
          </SheetClose>
        ) : (
          Comp
        );
      })}
    </>
  );
}
