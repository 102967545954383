// src/components/SelectComponent.tsx
"use client";
import _ from "lodash";
import { Select, SelectItem } from "@nextui-org/react";

type Params = {
  label: string;
  value: string;
  setValue: (string) => void;
  items: { env: string; label: string }[];
  radius: "none" | "sm" | "md" | "lg" | "full" | undefined;
};

function SelectComponent({ label, value, setValue, items, ...props }: Params) {
  const handleSelectionChange = (value) => {
    const unwrapFromSet = Array.from(value)[0];
    setValue(unwrapFromSet);
  };

  return (
    <Select
      label={label}
      disallowEmptySelection
      selectedKeys={new Set([value])}
      onSelectionChange={handleSelectionChange}
      {...props}
    >
      {items.map((item) => (
        // Adding pointer-events-auto is to prevent the Sheet component from blocking click events.
        <SelectItem className="pointer-events-auto" key={item.env}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
}

export default SelectComponent;
